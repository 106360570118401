import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-clone-ro',
  templateUrl: './add-clone-ro.component.html',
  styleUrl: './add-clone-ro.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AddCloneRoComponent {

  constructor(
    private dialogRef: MatDialogRef<AddCloneRoComponent>,
  ){}

  close(result: number): void {
    this.dialogRef.close(result);
  }
  
}

