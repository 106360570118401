<h3 mat-dialog-title class="dialog-title mx-3">
    <mat-icon class="icon me-3">info</mat-icon>
    <span >Essa peça já está adicionada.</span>
  </h3>
  <p class="dialog-message mx-3">Deseja aumentar a quantidade ou adicionar separadamente?</p>
  
  <mat-dialog-actions align="end" class="dialog-actions">
    <button aria-label="no"
            mat-raised-button
            class="dialog-button cancel-button"
            type="button"
            (click)="cancel()">
      {{ 'ACTION.SHARED.CANCEL' | translate }}
    </button>
    <button aria-label="yes"
            mat-raised-button
            title="Aumentar quantidade da peça selecionada"
            class="dialog-button"
            color="primary"
            type="button"
            (click)="onAmount()">
      {{ 'ACTION.SHARED.AMOUNT' | translate }}
    </button>
    <button aria-label="yes"
            mat-raised-button
            class="dialog-button mr-3"
            color="primary"
            type="button"
            title="Adicionar peça selecionada separadamente"
            (click)="onSeparate()">
      {{ 'ACTION.SHARED.SEPARATELY' | translate }}
    </button>
  </mat-dialog-actions>
  