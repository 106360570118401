import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { Part } from 'src/app/interface/part';
import { LayoutService } from 'src/app/service/layout.service';
import { PartService } from 'src/app/service/part.service';
import { SnackbarService } from 'src/app/service/snackbar.service';

@Component({
  selector: 'app-validate-ncm-before-closing-os',
  templateUrl: './validate-ncm-before-closing-os.component.html',
  styleUrl: './validate-ncm-before-closing-os.component.scss',
})
export class ValidateNcmBeforeClosingOsComponent implements OnInit { 
  
  @ViewChild(MatTooltip) tooltip: MatTooltip;

  form: FormGroup;
  public parts: Part[] = []
  columns = ['name', 'description', 'ncm', 'newNcm'];
  partsChangeNcm: partsChangeNcm[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ValidateNcmBeforeClosingOsComponent>,
    private partService: PartService,
    private snackBar: SnackbarService,
    private layout: LayoutService
  ) { }
  
  ngOnInit() {
    this.layout.loader = true;
    this.parts = this.data.invalidParts;
  }

  close(partsChanged?) {
    if(partsChanged){
      this.dialogRef.close(partsChanged);
    } else {
      this.dialogRef.close()
    }
  }

  truncateString(text: string, maxLength: number = 30): string {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
  }

  onChange(event: Event, part: Part) {
    const inputElement = event.target as HTMLInputElement;
    const newNcm = Number(inputElement.value);
    const partChanged = {
      part: part,
      newNcm: newNcm
    }
    const index = this.partsChangeNcm.findIndex(p => p.part.id === part.id);

    if (index !== -1) {
      this.partsChangeNcm[index].newNcm = newNcm;
    } else {
      this.partsChangeNcm.push(partChanged);
    }
  } 

  save(){
    let partsChanged: Part[] = [] 
    try{
      this.partsChangeNcm.map(part => {
        part.part.NCM = part.newNcm;
        partsChanged.push(part.part);
      });
      this.partService.addOrUpdateMany(partsChanged);
      this.close(partsChanged);
      this.snackBar.success('PART.UPDATED_ALL', partsChanged.length)
      } catch (error) {
        console.error(error)
        this.snackBar.error('PART.NOT_UPDATED_ALL', error)
    } finally {
      this.layout.loader = true;
    }
  }

  showTooltip() {
    this.tooltip.show();
  }

  get closeOsDialogTooltip(): string{
      return 'Alguma(s) peça(s) na OS, contém ncm inválido. \nPara continuar o processo de fechamento da OS é necessário corrigir o ncm da(s) mesma(s).'
  }
}

export interface partsChangeNcm {
  part: Part,
  newNcm: number
}
