import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Output, type OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { LayoutService } from 'src/app/service/layout.service';

@Component({
  selector: 'app-add-part-repeated',
  standalone: false,
  templateUrl: './add-part-repeated.component.html',
  styleUrl: './add-part-repeated.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPartRepeatedComponent implements OnInit {

  @Output() update : number;
  
  sub: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      text: string,
      value: number
    },
    public layout: LayoutService,
    public dialogRef: MatDialogRef<AddPartRepeatedComponent>,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
  }

  cancel() {
    this.data.value = 0;
    this.dialogRef.close(this.data.value)
  }

  onAmount() {
    this.data.value = 1;
    this.dialogRef.close(this.data.value); // Fechar o diálogo e enviar o valor 1
  }

  onSeparate() {
    this.data.value = 2;
    this.dialogRef.close(this.data.value); // Fechar o diálogo e enviar o valor 2
  }


}
