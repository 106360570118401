<h1 mat-dialog-title>
    Deseja gerar um(a) Orçamento ou Os?
</h1>

<mat-dialog-actions align="end">
    <button aria-label="save"
            mat-raised-button
            color="primary"
            type="button"
            (click)="close(1)">
        Orçamento
    </button>
    <button type="button"
            aria-label="save"
            mat-raised-button
            color="primary"
            (click)="close(2)">
        OS
    </button>
</mat-dialog-actions>