import {  Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs';

import { Breakpoint } from 'src/app/interface/breakpoint';
import { LayoutService } from 'src/app/service/layout.service';

@Component({
  selector: 'app-vehicle-breakpoint-part-name',
  templateUrl: './vehicle-breakpoint-part-name.component.html',
  styleUrl: './vehicle-breakpoint-part-name.component.scss'
})
export class VehicleBreakpointPartNameComponent implements OnInit {

  formController = new FormGroup({
    vehiclePartName: new FormControl('')
  })

  isMobile: boolean;

  constructor(
    private _dialogRef: MatDialogRef<VehicleBreakpointPartNameComponent>,
    private _layoutService: LayoutService,
  ) { }

  ngOnInit(): void {
    this._layoutService.isHandset$.pipe(first()).subscribe(isMobile => this.isMobile = isMobile)
  }

  confirm(){
    const { vehiclePartName } = this.formController.value;
    this._dialogRef.close(vehiclePartName.trim());
  }

  cancel(){
    this._dialogRef.close();
  }

}
