<h2 mat-dialog-title>Parte do veículo danificada:</h2>
<mat-dialog-content>
  <form autocomplete="off"
  [formGroup]="formController"  
  class="w-100">
  <mat-form-field class="w-100" *ngIf="!isMobile">
    <mat-label>Nome da parte do veículo (opcional)</mat-label>
    <input #vehiclePartInput
          matInput 
          formControlName="vehiclePartName" 
          cdkFocusInitial
          placeholder="Descrição"
          >
  </mat-form-field>
  <div *ngIf="isMobile" class="form-group w-100">
    <label>Nome da parte do veículo (opcional)</label>
    <input  #vehiclePartInput
            matInput 
            formControlName="vehiclePartName" 
            cdkFocusInitial
            placeholder="Descrição">
  </div>
</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button 
         mat-raised-button
         (click)="cancel()"
         color="warn"
         >Cancelar</button>
  <button 
         mat-raised-button
         (click)="confirm()"  
         color="primary"
         type="button">Confirmar</button>
</mat-dialog-actions>