<div class="d-flex justify-content-between">
    <div class="center">
        <h1 mat-dialog-title >
            Peças com Ncm inválido
        </h1> 
        <mat-icon (click)="showTooltip()"
            #tooltip="matTooltip"
            class="margin-top"
            [matTooltip]="closeOsDialogTooltip"
            [matTooltipHideDelay]="1000"
            color="warn">help</mat-icon>
    </div>
        
    
    <div class="margin">
        <button mat-raised-button
            type="button"
            color="primary"
            (singleClick)="save()">
                Salvar
        </button>
        <button mat-icon-button
                type="button"
                (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    
</div>

<div class="table-container m-4">
    <table mat-table [dataSource]="parts">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Código </th>
        <td mat-cell *matCellDef="let row"> {{ row.code }} </td>
      </ng-container>
  
      <ng-container matColumnDef="description" class="limit">
        <th mat-header-cell *matHeaderCellDef> Descrição </th>
        <td mat-cell *matCellDef="let row"> {{ truncateString(row.description) }} </td>
      </ng-container>

      <ng-container matColumnDef="ncm">
        <th mat-header-cell *matHeaderCellDef> Ncm atual </th>
        <td mat-cell *matCellDef="let row"> {{ row.NCM }} </td>
      </ng-container>

      <ng-container matColumnDef="newNcm">
        <th mat-header-cell *matHeaderCellDef> Novo ncm </th>
        <td mat-cell *matCellDef="let row"> 
            <input 
                money
                [decimalPoints]="0"
                [noClass]="true"
                [thousandSeparator]="''"
                type="text" 
                class="form-control" 
                placeholder="Adicione um ncm válido"
                aria-describedby="basic-addon1"
                (change)="onChange($event, row)">
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: ['name', 'description','ncm', 'newNcm'];"></tr>
    </table>
</div>



